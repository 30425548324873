import type { Ref } from 'vue';
import {
  computed, onBeforeMount, onBeforeUnmount, ref, toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import type { CaptchaType, LoginInput } from '@leon-hub/api-sdk';
import { G2SVRequiredExceptionCode, LoginType, PinCodeRequiredExceptionCode } from '@leon-hub/api-sdk';
import { assert, isObject, isString } from '@leon-hub/guards';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import type { FormSchema } from '@leon-hub/form-utils';
import { CasAuthPostMessageEvent, PostMessageBus } from '@leon-hub/postmessage-bus';
import { logger } from '@leon-hub/logging';
import { GqlApiCaptchaRequiredError } from '@leon-hub/api';
import type { GqlApiResponseErrorCode } from '@leon-hub/api';
import type { BaseError } from '@leon-hub/errors';
import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { VTabsProps } from 'web/src/components/Tabs/VTabs/types';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import useLoginStore from 'web/src/modules/login/store/useLoginStore';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import { isFormPhoneValue } from 'web/src/components/Form/guards';
import { useSiteConfigStore } from 'web/src/modules/core/store/useSiteConfigStore';
import { LoginFieldName } from 'web/src/modules/login/store/enums';
import useCasAuthStore from 'web/src/modules/cas/store/useCasAuthStore';
import { getLoginType } from 'web/src/modules/user/store/utils';
import type { CordovaLoginComposable } from 'web/src/modules/login/pages/LoginRoutePage/composables/useCordovaLogin';
import useCordovaLogin from 'web/src/modules/login/pages/LoginRoutePage/composables/useCordovaLogin';
import { LOCAL_STORAGE_LOGIN_TAB_KEY, LoginApiErrorCode } from 'web/src/modules/login/pages/LoginRoutePage/types';
import useCaptchaResolver from 'web/src/modules/captcha/composables/useCaptchaResolver';
import type { DefaultField } from 'web/src/modules/login/store/types';
import type { FormOutput, FormUiSchema } from 'web/src/components/Form';
import type { FormData, FormPhoneValue } from 'web/src/components/Form/types';
import { AnalyticsModalType, DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useDeviceFingerprints } from 'web/src/modules/identity/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useUserStore } from 'web/src/modules/user/store/useUserStore';
import HintBlockKind from 'web/src/components/HintBlock/VHintBlock/enums/HintBlockKind';
import type { VHintBlockProps } from 'web/src/components/HintBlock/VHintBlock/types';
import ButtonType from 'web/src/components/Button/VButton/enums/ButtonType';
import ButtonKind from 'web/src/components/Button/VButton/enums/ButtonKind';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import useRouterStore from 'web/src/modules/core/store/useRouterStore';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useErrorHandler } from 'web/src/modules/core/composables/errors';

const defaultFakePassword = '*******';

function getPhoneString(phone: Partial<FormPhoneValue>): string {
  const { prefix, suffix } = phone;
  return `+${prefix ?? ''}${suffix ?? ''}`;
}

function checkPhoneMatch(phoneString: string, phone: Partial<FormPhoneValue>): boolean {
  return phoneString === getPhoneString(phone);
}

export interface LoginPageComposable {
  loginForm: Ref<Optional<VFormRef>>;
  schema: Ref<FormSchema>;
  uiSchema: Ref<FormUiSchema>;
  isSubmitPending: Ref<boolean>;
  isFormReady: Ref<boolean>;
  isCaptchaEnabled: Ref<boolean>;
  tabsProperties: Ref<VTabsProps>;
  incorrectLoginMessage: Ref<Maybe<string>>;
  incorrectHintBlockProperties: Ref<VHintBlockProps>;
  submitButtonProperties: Ref<VButtonProps>;
  loginType: Ref<LoginType>;
  isShowTabsLogin: Ref<boolean>;

  onClickSingInButton(): void;

  onTabClick(clickedTab: LoginType): void;

  onChange(data: FormOutput<DefaultField, LoginFieldName>): Promise<boolean>;

  onInput(payload: FormOutput<DefaultField, LoginFieldName>): void;

  onSignIn(data: FormOutput<DefaultField, LoginFieldName>): Promise<void>;

  onRegistrationButtonClick(): void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useLoginPage(): LoginPageComposable {
  let cordovaLogin: Optional<CordovaLoginComposable>;
  let windowRef: Window | null = null;
  let postMessageBus: PostMessageBus | null = null;

  const localStorageManager = useLocalStorageManager();
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();
  const router = useRouter();
  const analytics = useAnalytics();
  const { getDeviceFingerprints } = useDeviceFingerprints();
  const errorConverter = useErrorsConverter();
  const {
    setCaptchaToken,
    resolveCaptcha,
  } = useCaptchaResolver();
  const { handleError } = useErrorHandler(router);

  const userStore = useUserStore();
  const userLoginStore = useLoginStore();
  const siteConfigStore = useSiteConfigStore();
  const casAuthStore = useCasAuthStore();
  const routerStore = useRouterStore();
  const pinCodeModule = usePinCodeStore();

  const loginType = toRef(userLoginStore, 'loginType');
  const phoneNumber = toRef(userLoginStore, 'phoneNumber');
  const isCasAuthEnabled = toRef(siteConfigStore, 'isCasAuthEnabled');
  const loginTypes = toRef(siteConfigStore, 'loginTypes');
  const usernames = toRef(casAuthStore, 'usernames');
  const isCookiesOk = toRef(casAuthStore, 'isCookiesOk');
  const defaultFieldLogin = toRef(userLoginStore, 'defaultFieldLogin');

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    cordovaLogin = useCordovaLogin({
      resolveCaptcha,
    });
  }

  const submitButtonTitle = ref('');
  const loginForm = ref<VFormRef>();
  const incorrectLoginMessage = ref<Maybe<string>>(null);
  const isAutoLoginFilled = ref(false);
  const isFormReady = ref(false);
  const isSubmitPending = process.env.VUE_APP_PLATFORM_CORDOVA
    ? cordovaLogin?.isSubmitPending ?? ref(false)
    : ref(false);

  function getLabelByLoginType(value: LoginType): string {
    switch (value) {
      case LoginType.EMAIL:
        return $translate('WEB2_LOGIN_EMAIL').value;
      case LoginType.PHONE:
        return $translate('WEB2_LOGIN_PHONE').value;
      case LoginType.ACCOUNT:
        return $translate('WEB2_LOGIN_ACCOUNT').value;
      default:
        return '';
    }
  }

  const tabsProperties = computed(() => ({
    items: loginTypes.value?.map(({ type }) => ({
      id: type,
      label: getLabelByLoginType(type),
    })) ?? [],
    activeId: loginType.value,
    type: TabsType.UPPERCASE,
  }));

  const casUsername = computed(() => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA && cordovaLogin?.usernameFromCordova.value) {
      return cordovaLogin.usernameFromCordova.value;
    }
    return usernames.value[0] || '';
  });

  const fakePassword = computed(() => (casUsername.value
  && loginType.value === getLoginType(casUsername.value) ? defaultFakePassword : ''));

  function saveFormData(): void { // need to save state of form when switch tabs
    const formOutput = loginForm.value?.getCurrentOutput();
    const login = formOutput?.formData?.login || '';
    if ((isFormPhoneValue(login) || isString(login))) {
      userLoginStore.setUiSchemaLoginDefault({
        activeTab: loginType.value,
        value: login,
      });
    }
  }

  const incorrectHintBlockProperties = computed(() => ({
    kind: HintBlockKind.ERROR,
    label: incorrectLoginMessage.value || undefined,
  }));

  const submitButtonProperties = computed(() => ({
    type: ButtonType.SUBMIT,
    kind: ButtonKind.PRIMARY,
    isLoading: isSubmitPending.value,
    label: submitButtonTitle.value,
  }));

  const isShowTabsLogin = computed<boolean>(
    () => (tabsProperties.value.items?.length > 1),
  );

  function onFormPrefill(type: LoginType, value: string, newFakePassword: string): void {
    userLoginStore.prefillLoginForm(type, value, newFakePassword);
    isAutoLoginFilled.value = !!newFakePassword;
  }

  function onTabClick(clickedTab: LoginType): void {
    if (loginType.value !== clickedTab) {
      incorrectLoginMessage.value = null;
      saveFormData();
      userLoginStore.setLoginType(clickedTab);

      if (isCasAuthEnabled.value && casUsername.value && loginType.value === getLoginType(casUsername.value)) {
        userLoginStore.prefillLoginForm(loginType.value, casUsername.value, fakePassword.value);
        isAutoLoginFilled.value = Boolean(fakePassword.value);

        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (process.env.VUE_APP_PLATFORM_CORDOVA) {
          if (cordovaLogin?.usernameFromCordova.value
            && clickedTab === getLoginType(cordovaLogin.usernameFromCordova.value)) {
            void cordovaLogin?.insertAuthData(onFormPrefill);
          }
        }
      } else if (!defaultFieldLogin.value[clickedTab]) {
        onClear();
      }
    }
  }

  function restoreSubmitButtonInitialState(): void {
    submitButtonTitle.value = $translate('JS_BONUSPROMO_LOGIN').value;
    isSubmitPending.value = false;
  }

  onBeforeUnmount(() => {
    postMessageBus?.dispose();
    userLoginStore.resetUiSchemaLoginDefault();
    userLoginStore.setUiSchemaDefault({
      field: LoginFieldName.PASSWORD,
      value: '',
    });
    if (phoneNumber.value) {
      userLoginStore.setPhoneNumber(null);
    }
  });

  useBusSafeSubscribe(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, restoreSubmitButtonInitialState);
  useBusSafeSubscribe(BusEvent.CAPTCHA_SERVICE_ERROR, (data) => {
    restoreSubmitButtonInitialState();
    handleError(data.error);
  });

  onBeforeMount(async () => {
    submitButtonTitle.value = $translate('JS_BONUSPROMO_LOGIN').value;

    const savedLoginType = (localStorageManager.getItem(LOCAL_STORAGE_LOGIN_TAB_KEY) as LoginType);

    userLoginStore.setLoginType(
      loginTypes.value?.find(({ type }) => type === savedLoginType)?.type
      ?? loginTypes.value?.find(({ isDefault }) => isDefault)?.type
      ?? tabsProperties.value.items[0].id,
    );

    if (isCasAuthEnabled.value && !casUsername.value) {
      await casAuthStore.getSavedLogins();
    }

    if (casUsername.value) {
      const casLoginType = getLoginType(casUsername.value);
      const isCasLoginTypeEnabled = loginTypes.value?.some(({ type }) => type === casLoginType);

      if (isCasLoginTypeEnabled) {
        userLoginStore.setLoginType(getLoginType(casUsername.value));
        userLoginStore.prefillLoginForm(getLoginType(casUsername.value), casUsername.value, fakePassword.value);
        isAutoLoginFilled.value = Boolean(fakePassword.value);
      }
    }

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await cordovaLogin?.insertAuthData(onFormPrefill);
    }
    isFormReady.value = true;
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await cordovaLogin?.authByFinger();
    }
  });

  function checkCookiesHandler(): void {
    let checkboxValue = false;
    casAuthStore.getSavedLogins().then(() => {
      if (isCookiesOk.value && postMessageBus) {
        postMessageBus.emit(CasAuthPostMessageEvent.cookieIsOk);
        checkboxValue = true;
      } else {
        windowRef?.location.replace(`${casAuthStore.authUrl}&cookieDisabled=true`);
      }
      userLoginStore.setUiSchemaDefault({
        field: LoginFieldName.CHECKBOX,
        value: checkboxValue,
      });
    }).catch(() => logger.warn('cannot fetch saved logins'));
  }

  async function saveCasLogin(loginOptions: LoginInput): Promise<void> {
    const casLoginInput: { username: string; password?: string } = { username: loginOptions.username };

    if (!isAutoLoginFilled.value) {
      casLoginInput.password = loginOptions.password;
    }

    await casAuthStore.loginCas(casLoginInput);
  }

  function showPasswordSavingWarning(): void {
    const { subscribe, promise } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: $translate('WEB2_MODALTITLE_ATTENTION').value,
        confirmMessage: $translate('JS_PASSWORD_SAVING_IS_DISABLED').value,
        buttons: [{
          label: $translate('JS_ENABLE').value,
        }],
        analyticsModalType: AnalyticsModalType.WARNING,
        dataTestId: 'show-password-saving-warning',
      },
    });
    let confirm = false;
    subscribe({
      [DialogAction.CONFIRM]: () => {
        confirm = true;
      },
    });

    promise.then(() => {
      if (confirm) {
        windowRef = window.open(
          casAuthStore.authUrl,
          'CasAuth',
          'menubar=no,location=no,resizable=yes,status=no',
        );

        assert(isObject(windowRef), 'window should be an instance of Window');
        postMessageBus = new PostMessageBus({
          target: windowRef,
          targetOrigin: '*',
          skipSourceCheck: true,
          initiator: 'LoginPage',
        });

        postMessageBus.on(CasAuthPostMessageEvent.checkCookie, checkCookiesHandler);
        postMessageBus.on(CasAuthPostMessageEvent.reloadPage, () => window.location.reload());
      } else {
        userLoginStore.setUiSchemaDefault({
          field: LoginFieldName.CHECKBOX,
          value: false,
        });
      }
      return casAuthStore.authUrl;
    }).catch(() => logger.warn('cannot close showPasswordSavingWarning'));
  }

  async function recheckCookies(): Promise<void> {
    try {
      await casAuthStore.checkCookie();
    } catch (error) {
      logger.error('Can not setup casAuth', error);
    }
  }

  async function handleCaptcha(
    data: FormOutput<FormData, LoginFieldName>,
    forcedCaptchaType?: Maybe<CaptchaType>,
  ): Promise<void> {
    const captchaType = forcedCaptchaType ?? userLoginStore.captchaType;

    // eslint-disable-next-line no-param-reassign
    data.formData[LoginFieldName.CAPTCHA_TOKEN] = await resolveCaptcha(captchaType);
    // eslint-disable-next-line no-param-reassign
    data.formData[LoginFieldName.CAPTCHA_TYPE] = captchaType;
    userLoginStore.setUiSchemaDefault({
      field: LoginFieldName.CAPTCHA_TYPE,
      value: captchaType,
    });
  }

  function handleLoginError(error: BaseError, loginOptions: LoginInput): void {
    const isGoingToReset = !!pinCodeModule.resetToken;

    if (error.code.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) {
      if (isGoingToReset) {
        pinCodeModule.setStepWithLS('RESET_AFTER_LOGIN');
      } else {
        userStore.setPinCodeData({
          pinCode: '',
          captchaAnswer: loginOptions.captchaAnswer,
          captchaType: loginOptions.captchaType,
          fingerprint: loginOptions.fingerprint,
          fpjsVisitorId: loginOptions.fpjsVisitorId,
          cordovaDeviceUUID: loginOptions.cordovaDeviceUUID,
          appGUID: loginOptions.appGUID,
          loginType: loginOptions.loginType,
        });
        pinCodeModule.setStepWithLS('LOGIN');
      }

      void router.replace({ name: RouteName.PIN_CODE });
    } else if (error.code.equals(G2SVRequiredExceptionCode.G2SV_REQUIRED)) {
      void router.push({ name: RouteName.TWO_FACTOR_AUTHENTICATION_LOGIN });
    } else if (error.code.equals(LoginApiErrorCode.LOGIN_BY_EMAIL_FAILED)
      || error.code.equals(LoginApiErrorCode.LOGIN_BY_ACCOUNT_FAILED)
      || error.code.equals(LoginApiErrorCode.LOGIN_BY_PHONE_FAILED)) {
      incorrectLoginMessage.value = error.message;
      isAutoLoginFilled.value = false;
      saveFormData();
      userLoginStore.setUiSchemaDefault({
        field: LoginFieldName.PASSWORD,
        value: '',
      });
    } else {
      showLoginErrorModal(error);
    }
  }

  function showLoginErrorModal(error: BaseError): void {
    let title;

    if (error.code.equals(LoginApiErrorCode.FAILURE_INVALID_TOKEN) || error.code.equals(LoginApiErrorCode.FAILURE_TOKEN_AUTH_DISABLED)) {
      title = $translate('JAVA_SSO_INVALID_TOKEN_ERROR_TITLE').value;
    } else {
      title = $translate('WEB2_MODALTITLE_ATTENTION').value;
    }

    showDialog({
      presetName: PresetName.ALERT_WARNING,
      options: {
        title,
        confirmMessage: error.message,
        dataTestId: 'login-error',
      },
    }).promise.then(() => {
      isAutoLoginFilled.value = false;
      saveFormData();
      userLoginStore.setUiSchemaDefault({
        field: LoginFieldName.PASSWORD,
        value: '',
      });
    }).catch(() => logger.warn('cannot show showLoginErrorModal'));
  }

  async function onChange(data: FormOutput<DefaultField, LoginFieldName>): Promise<boolean> {
    const login = data.formData[LoginFieldName.LOGIN];
    if (login) {
      if (typeof login !== 'string') {
        userLoginStore.setUiSchemaLoginDefault({
          activeTab: LoginType.PHONE,
          value: login,
        });

        const isPrefixChanged = Boolean(phoneNumber.value)
          && phoneNumber.value?.prefix !== login.prefix;
        userLoginStore.setPhoneNumber({
          prefix: login.prefix,
          suffix: login.suffix,
        });

        if (data.field === LoginFieldName.LOGIN_PREFIX
          && isAutoLoginFilled.value
          && isPrefixChanged
        ) {
          userLoginStore.setUiSchemaDefault({
            field: LoginFieldName.PASSWORD,
            value: '',
          });
          isAutoLoginFilled.value = false;
        }
      }
      const errorsInInputs = data.errors?.has(LoginFieldName.LOGIN) ?? data.errors?.has(LoginFieldName.PASSWORD);
      if (data.field === LoginFieldName.LOGIN
        && !errorsInInputs
        && isCookiesOk.value
        && isAutoLoginFilled.value) {
        userLoginStore.setUiSchemaLoginDefault({
          activeTab: loginType.value,
          value: login,
        });
        userLoginStore.setUiSchemaDefault({
          field: LoginFieldName.PASSWORD,
          value: '',
        });
        isAutoLoginFilled.value = false;
      }
      if (data.field === LoginFieldName.LOGIN
        && !errorsInInputs
        && typeof login === 'string'
        && isCookiesOk.value
        && !isAutoLoginFilled.value
        && usernames.value.includes(login)
        && isCasAuthEnabled.value) {
        userLoginStore.setUiSchemaLoginDefault({
          activeTab: loginType.value,
          value: login,
        });
        userLoginStore.setUiSchemaDefault({
          field: LoginFieldName.PASSWORD,
          value: fakePassword.value,
        });
        isAutoLoginFilled.value = true;
      }
      if (data.field === LoginFieldName.PASSWORD
        && !errorsInInputs
        && isCookiesOk.value
        && isAutoLoginFilled.value) {
        isAutoLoginFilled.value = false;
      }
      if (data.field !== LoginFieldName.CHECKBOX && !isCookiesOk.value) {
        return false;
      }
      if (data.field === LoginFieldName.CHECKBOX && data.formData.checkbox && !process.env.VUE_APP_PLATFORM_CORDOVA) {
        await casAuthStore.getSavedLogins();

        if (!isCookiesOk.value) {
          if (process.env.VUE_APP_LAYOUT_DESKTOP) {
            await recheckCookies();
          }
          showPasswordSavingWarning();
        }
      }
    } else {
      onClear();
    }
    return true;
  }

  function onClear(): void {
    userLoginStore.setUiSchemaLoginDefault({
      activeTab: loginType.value,
      value: loginType.value === LoginType.PHONE ? {} : '',
    });
    userLoginStore.setUiSchemaDefault({
      field: LoginFieldName.PASSWORD,
      value: '',
    });
    isAutoLoginFilled.value = false;
  }

  function onInput(payload: FormOutput<DefaultField, LoginFieldName>): void {
    const { login, password } = payload.formData;

    if (isFormPhoneValue(login) && payload.field === LoginFieldName.LOGIN_SUFFIX && isAutoLoginFilled.value) {
      userLoginStore.setUiSchemaDefault({
        field: LoginFieldName.PASSWORD,
        value: '',
      });
      isAutoLoginFilled.value = false;
    }

    if (isString(login) && payload.field === LoginFieldName.LOGIN) {
      const isStartsWithSpace: boolean = /^\s/.test(login);
      const isEndsWithSpace: boolean = /\s$/.test(login);

      userLoginStore.setUiSchemaLoginDefault({
        activeTab: loginType.value,
        value: (isStartsWithSpace || isEndsWithSpace) ? login.trim() : login,
      });
    }

    if (isString(password) && payload.field === LoginFieldName.PASSWORD) {
      userLoginStore.setUiSchemaDefault({
        field: LoginFieldName.PASSWORD,
        value: password,
      });
    }

    const captchaToken = payload.formData[LoginFieldName.CAPTCHA_TOKEN];

    if (captchaToken) {
      setCaptchaToken(captchaToken);
    }
  }

  async function logIn(loginOptions: LoginInput, save: boolean): Promise<void> {
    if ((save || isAutoLoginFilled.value) && isCookiesOk.value) {
      const { password, ...options } = loginOptions;
      try {
        await saveCasLogin(loginOptions);
      } catch {
        await userStore.auth(loginOptions);
        return;
      }
      await userStore.authCas({
        ...options,
        ssoLoginTicket: casAuthStore.ticket,
        savePassword: null,
      });
    } else {
      await userStore.auth(loginOptions);
    }
  }

  async function onSignIn(data: FormOutput<DefaultField, LoginFieldName>): Promise<void> {
    const { errors } = data;

    // TODO: JSON Scheme dynamic validation
    const hasErrors = errors && !(errors.size === 1 && errors.get('captchaAnswer'));
    if (hasErrors) {
      return;
    }

    if (isCasAuthEnabled.value && !isCookiesOk.value) {
      await recheckCookies();
    }

    let { password } = data.formData;

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      if (password === fakePassword.value) {
        password = cordovaLogin?.passwordFromCordova.value ?? password;
      }
    }

    isSubmitPending.value = true;

    const {
      deviceFingerprint,
      deviceFingerprintJs,
    } = await getDeviceFingerprints();

    const {
      login,
      checkbox = false,
      captchaAnswer = null,
      captchaType = null,
      fingerprint = deviceFingerprint,
    } = data.formData;

    let currentUsername: string;

    if (typeof login !== 'string') {
      currentUsername = getPhoneString(login);
      if (casUsername.value
        && getLoginType(casUsername.value) === LoginType.PHONE
        && currentUsername !== casUsername.value
        && checkPhoneMatch(casUsername.value, login)
      ) {
        currentUsername = casUsername.value;
      }
    } else {
      currentUsername = `${login}`;
    }

    submitButtonTitle.value = $translate('JSPACC_LOGIN_ENTER').value;

    const loginOptions: LoginInput = {
      username: currentUsername,
      password,
      captchaType,
      captchaAnswer,
      fingerprint,
      fpjsVisitorId: deviceFingerprintJs,
      loginType: loginType.value,
    };

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      loginOptions.cordovaDeviceUUID = cordovaLogin?.cordovaDeviceUUID.value;
      loginOptions.appGUID = cordovaLogin?.appGUID.value;
    }

    try {
      await logIn(loginOptions, checkbox);
      isSubmitPending.value = false;
    } catch (rawError) {
      if (rawError instanceof GqlApiCaptchaRequiredError) {
        await handleCaptcha(data, rawError.extensions.captchaType);
        await onSignIn(data);
        return;
      }

      const error = errorConverter.convertToBaseError(rawError);
      const errorCode: GqlApiResponseErrorCode = error.code;

      if (checkbox && isCookiesOk.value && (
        errorCode.equals(G2SVRequiredExceptionCode.G2SV_REQUIRED
          || errorCode.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED))
      )) {
        await saveCasLogin(loginOptions);
      }

      if (checkbox
        && isCookiesOk.value
        && !errorCode.equals(G2SVRequiredExceptionCode.G2SV_REQUIRED)
        && !errorCode.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)
      ) {
        await casAuthStore.deleteSavedLogins({ username: currentUsername });
      }

      handleLoginError(error, loginOptions);

      analytics.push(AnalyticsEvent.Z_LOGGED_IN, {
        login: {
          error: {
            message: error.message,
            code: `${errorCode}`,
          },
        },
      });

      restoreSubmitButtonInitialState();
    }
    if (!checkbox && isCookiesOk.value) {
      await casAuthStore.deleteSavedLogins({ username: currentUsername });
    }
  }

  function onClickSingInButton(): void {
    loginForm.value?.submit();
  }

  function sendMetricOnRegistrationClick(): void {
    const actionName = 'pageLoginRegistration';
    const payload: { registration: string | Record<string, string | symbol> } = { registration: actionName };

    const attemptedRoute = routerStore.getLoginRedirectRoute();
    if (attemptedRoute) {
      const attemptedRouteEntryName = router.resolve(attemptedRoute)?.name;

      if (attemptedRouteEntryName) {
        payload.registration = {
          [actionName]: attemptedRouteEntryName,
        };

        analytics.clickMap(payload);
      }
    }
  }

  function onRegistrationButtonClick(): void {
    sendMetricOnRegistrationClick();
    void router.push({ name: RouteName.REGISTRATION });
  }

  return {
    loginForm,
    tabsProperties,
    onTabClick,
    isSubmitPending,
    isFormReady,
    schema: toRef(userLoginStore, 'schema'),
    uiSchema: toRef(userLoginStore, 'uiSchema'),
    isCaptchaEnabled: toRef(userLoginStore, 'isCaptchaEnabled'),
    onChange,
    onInput,
    onSignIn,
    incorrectLoginMessage,
    incorrectHintBlockProperties,
    submitButtonProperties,
    onClickSingInButton,
    onRegistrationButtonClick,
    loginType,
    isShowTabsLogin,
  };
}
